<template>
  <b-modal
    id="reject-action-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="REJECT DEPOSITS"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col md="3" />
          <b-col md="5">
            <b-form-group
              label-for="vi-reason"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="TerminalIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-reason"
                  v-model="form.reason"
                  placeholder="Enter Reason"
                  required
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-button
              v-can="'rider-finance-approve'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              type="submit"
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Reject</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BModal, BFormInput, BButton, BInputGroup, BInputGroupPrepend, VBModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    form: {},
    depositSelected: [],
    financeType: '',
    modalLoading: false,
  }
}

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(selectedDeposits, financeType) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.depositSelected = selectedDeposits
      this.financeType = financeType
    },
    async handleSubmit() {
      try {
        this.modalLoading = true
        const payload = {
          reason: this.form.reason,
        }
        let res
        if (this.financeType === 'rider') {
          payload.rider_deposit_ids = this.depositSelected
          res = await FinanceRepository.rejectRiderFinanceDeposit(payload)
        } else if (this.financeType === 'agent') {
          payload.agency_deposit_ids = this.depositSelected
          res = await FinanceRepository.rejectAgentFinanceDeposit(payload)
        } else if (this.financeType === 'branch') {
          payload.branch_deposit_ids = this.depositSelected
          res = await FinanceRepository.rejectBranchFinanceDeposit(payload)
        } else if (this.financeType === 'rider-commission') {
          payload.rider_commission_ids = this.depositSelected
          res = await FinanceRepository.rejectRiderFinanceCommission(payload)
        } else if (this.financeType === 'agent-commission') {
          payload.agency_commission_ids = this.depositSelected
          res = await FinanceRepository.rejectAgentFinanceCommission(payload)
        }
        if (res.status === 202) {
          this.showSuccessMessage('Deposits Rejected Successfully')
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage('Deposit Rejection Failed')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
</style>
