<template>
  <b-modal
    id="make-deposit-action-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="MAKE PAYMENT"
  >
    <div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form
          @submit.prevent="makeDeposit"
        >
          <b-row>
            <b-col md="4">
              <v-select
                v-model="paymentSelected"
                :options="PaymentOptions"
                :reduce="option => option.id"
                label="name"
                placeholder="Select Payment Method"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
                <template #search="{attributes}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="!paymentSelected"
                    v-bind="attributes"
                  >
                </template>
              </v-select>
            </b-col>
            <b-col
              md="4"
              class="mt-1 mt-md-0"
            >
              <b-form-group
                label-for="vi-Deposit"
              >
                <b-form-file
                  id="wildcard"
                  v-model="form.file"
                  accept="image/*"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label-for="vi-remark"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TrelloIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-remark"
                    v-model="form.transaction_number"
                    type="text"
                    placeholder="Enter Transaction No"
                    pattern="[0-9]{0,10}"
                    title="Transaction number must be less than 10 digits"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label-for="vi-remark"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TerminalIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-remark"
                    v-model="form.remark"
                    placeholder="Enter Remark"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-button
                v-can="'rider-finance-paid'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                size="md"
                type="submit"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                />
                <span class="align-middle">Deposit</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormFile,
  BOverlay,
  BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    form: {},
    depositSelected: [],
    PaymentOptions: [],
    loading: false,
    paymentSelected: '',
    financeType: '',
  }
}

export default {
  components: {
    BOverlay,
    BRow,
    vSelect,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BFormFile,
    BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(selectedDeposits, financeType) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.depositSelected = selectedDeposits
      this.financeType = financeType
      this.readDataforPaymentMethod()
    },
    async readDataforPaymentMethod() {
      try {
        this.loading = true
        const { data } = (await FinanceRepository.getPaymentMethod()).data
        this.PaymentOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    makeDeposit() {
      if (this.depositSelected.length === 0 || this.depositSelected === []) {
        this.showErrorMessage('Please Select Deposits First')
      } else {
        this.loading = true
        const data = {
          transaction_number: this.form.transaction_number,
          payment_method_id: this.paymentSelected,
          comment: this.form.remark,
          status_key: 'f_key_2',
        }
        const receiptFile = this.form.file
        if (this.financeType === 'rider') {
          data.rider_deposit_ids = this.depositSelected
        } else if (this.financeType === 'agent') {
          data.agency_deposit_ids = this.depositSelected
        } else if (this.financeType === 'branch') {
          data.branch_deposit_ids = this.depositSelected
        } else if (this.financeType === 'rider-commission') {
          data.rider_commission_ids = this.depositSelected
        } else if (this.financeType === 'agent-commission') {
          data.agency_commission_ids = this.depositSelected
        } else {
          data.merchant_invoice_ids = this.depositSelected
        }
        this.handleSubmit(data, receiptFile)
      }
    },
    async handleSubmit(data, receiptFile) {
      try {
        let res
        if (this.financeType === 'rider') {
          res = await FinanceRepository.updateStatusRiderFinanceDeposit(data)
        } else if (this.financeType === 'agent') {
          res = await FinanceRepository.updateStatusAgentFinanceDeposit(data)
        } else if (this.financeType === 'branch') {
          res = await FinanceRepository.updateStatusBranchFinanceDeposit(data)
        } else if (this.financeType === 'rider-commission') {
          res = await FinanceRepository.updateStatusRiderFinanceCommission(data)
        } else if (this.financeType === 'agent-commission') {
          res = await FinanceRepository.updateStatusAgentFinanceCommission(data)
        } else {
          res = await FinanceRepository.updateStatusMerchantFinanceDeposit(data)
        }
        if (res.status === 202) {
          let uploadErrors = 0
          if (receiptFile) {
            await Promise.all(
              this.depositSelected.map(async deposit => {
                let slipRes
                if (this.financeType === 'rider') {
                  slipRes = await FinanceRepository.uploadRiderFinanceDeposit(deposit, receiptFile)
                } else if (this.financeType === 'agent') {
                  slipRes = await FinanceRepository.uploadAgentFinanceDeposit(deposit, receiptFile)
                } else if (this.financeType === 'branch') {
                  slipRes = await FinanceRepository.uploadBranchFinanceDeposit(deposit, receiptFile)
                } else {
                  slipRes = await FinanceRepository.uploadMerchantFinanceDeposit(deposit, receiptFile)
                }
                if (slipRes.status !== 200) {
                  uploadErrors += 1
                }
              }),
            )
          }
          if (uploadErrors === 0) {
            this.showSuccessMessage('Payment data uploaded successfully')
            this.open = false
            this.$parent.onClickRefresh()
          } else {
            this.showErrorMessage('Could not upload payment data')
          }
        } else {
          this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
</style>
