<template>
  <b-modal
    id="approve-action-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="CONFIRM APPROVAL"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-row>
        <b-col md="3" />
        <b-col md="5">
          <b-form-group
            label-for="vi-remark"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="TerminalIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-remark"
                v-model="form.remark"
                placeholder="Enter Remark"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-button
            v-can="'rider-finance-approve'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="makeApprove"
          >
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Approve</span>
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BButton, BInputGroup, BInputGroupPrepend, VBModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const FinanceRepository = RepositoryFactory.get('finance')

function initialState() {
  return {
    open: false,
    form: {},
    depositSelected: [],
    financeType: '',
    modalLoading: false,
  }
}

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(selectedDeposits, financeType) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.depositSelected = selectedDeposits
      this.financeType = financeType
    },
    makeApprove() {
      this.modalLoading = true
      if (this.depositSelected.length === 0 || this.depositSelected === []) {
        this.showErrorMessage('Please Select Deposits First')
      } else {
        const data = {
          comment: this.form.remark,
          status_key: 'f_key_3',
        }
        if (this.financeType === 'rider') {
          data.rider_deposit_ids = this.depositSelected
        } else if (this.financeType === 'agent') {
          data.agency_deposit_ids = this.depositSelected
        } else if (this.financeType === 'branch') {
          data.branch_deposit_ids = this.depositSelected
        } else if (this.financeType === 'rider-commission') {
          data.rider_commission_ids = this.depositSelected
        } else if (this.financeType === 'agent-commission') {
          data.agency_commission_ids = this.depositSelected
        }
        this.handleSubmit(data)
      }
    },
    async handleSubmit(data) {
      try {
        let res
        if (this.financeType === 'rider') {
          res = await FinanceRepository.approveStatusRiderFinanceDeposit(data)
        } else if (this.financeType === 'agent') {
          res = await FinanceRepository.approveStatusAgentFinanceDeposit(data)
        } else if (this.financeType === 'branch') {
          res = await FinanceRepository.approveStatusBranchFinanceDeposit(data)
        } else if (this.financeType === 'rider-commission') {
          res = await FinanceRepository.updateStatusRiderFinanceCommission(data)
        } else if (this.financeType === 'agent-commission') {
          res = await FinanceRepository.updateStatusAgentFinanceCommission(data)
        }
        if (res.status === 202) {
          this.showSuccessMessage('Deposits Approved Successfully')
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
</style>
